<template>
    <div class="examinerManagement">
        <div class="flex-box">
            <div class="left">
                <el-button type="success" @click="addExaminer">添加审批人</el-button>
            </div>
        </div>
        <el-table
            :data="examinerManagementList">
            <el-table-column
                label="审批人姓名"
                prop="mname"
                align="center"></el-table-column>
            <el-table-column
                label="审批类型"
                prop="typeStr"
                align="center"></el-table-column>
            <el-table-column
                label="操作"
                width="180px"
                prop="course"
                align="center">
                <template slot-scope="scope">
                    <el-button
                    type="primary"
                    @click="editExaminer(scope.row)">编辑
                    </el-button>
                    <el-button
                    type="danger"
                    @click="deleteExaminer(scope.row.id)">删除
                    </el-button>
                </template>
            </el-table-column>
            <!-- <el-table-column
                label="教师姓名"
                prop="mname"
                align="center"></el-table-column> -->
        </el-table>

        <el-dialog
            :title="addExaminerForm.managerid ? '编辑审批人' : '添加审批人'"
            :visible.sync="addExaminerFormDialogVisible"
            width="600px">
            <div class="center">
                <el-form
                :model="addExaminerForm"
                ref="addExaminerFormRef"
                label-width="100px"
                :rules="addExaminerFormRules">
                <!-- 审批人姓名
                <el-form-item label="姓名：" prop="name">
                    <el-input class="" v-model="addExaminerForm.name" placeholder="审批人姓名"></el-input>
                </el-form-item> -->
                <!-- 审批类型 -->
                    <el-form-item label="审批类型：" prop="type">
                        <el-select v-model="addExaminerForm.type" clearable
								   placeholder="请选择审批类型">
                            <el-option v-for="obj in examinerTypeList" :key="obj.type"
                                :label="obj.name" :value="obj.type"/>
                        </el-select>
                    </el-form-item>
                    <!-- 教师姓名(审批人姓名) -->
                    <el-form-item label="教师姓名：" prop="managerid">
                        <el-select v-model="addExaminerForm.managerid" clearable
								   filterable placeholder="请选择教师">
                            <el-option v-for="teacher in teachersList" :key="teacher.id"
                                :label="teacher.name" :value="teacher.id"/>
                        </el-select>
                    </el-form-item>
                </el-form>
            </div>
            <!-- 确认与取消 -->
            <span slot="footer" class="dialog-footer">
                <el-button @click="addExaminerFormDialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="addExaminerConfirm">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name:'examinerManagement',
    data(){
        return {
            teachersList:[],
            addExaminerForm:{
                id:'',
                type:'',
                managerid:''
            },
            addExaminerFormRules:{
                // name: [{required: true}],
                type: [{required: true}],
                managerid: [{required: true}],
            },
            addExaminerFormDialogVisible:false,
            examinerTypeList:[
                {type:1,name:'请假审批'},
                {type:2,name:'报修审批'},
                {type:3,name:'用车审批 '},
                {type:4,name:'报修人员'},
                {type:5,name:'年度考核'},
                {type:6,name:'公文管理'},
                {type:7,name:'临时假审批'},
            ],
            examinerManagementList:[],
        }
    },
    created(){
        this.getTeachersList()
        this.loadExaminerManagement(1)
    },
    methods:{
        getTeachersList(){
            // 教师列表接口
            this.$post('/media/queryAllManager.do')
            .then(res => {
                // console.log(res)
                this.teachersList = res.data
            })
        },
        // 获取初始页面数据
        loadExaminerManagement(){
            const that = this;
            this.$post("/base/queryApprover.do",{})
            .then((res) => {
                // console.log(res.data.rows)
                that.examinerManagementList = res.data;
                console.log(that.examinerManagementList)
            })
            .catch((err) => {
                    that.$message({
                    type: "warning",
                    message: err.message,
                });
            });
        },
        // 添加审批人
        addExaminer(){
            this.addExaminerFormDialogVisible = true
            this.$nextTick(() => {
              console.log(1)
                this.$refs.addExaminerFormRef.resetFields()
                this.addExaminerForm.id = ''
            })
        },
        // 编辑
        editExaminer(row){
		  // 显示对话框
		  this.addExaminerFormDialogVisible = true
            // 把数据赋值给编辑表单
            this.$nextTick(()=>{
                this.addExaminerForm.id = row.id
                this.addExaminerForm.type = row.type
                this.addExaminerForm.managerid = row.managerid
            })
        },
        // 表单提交
        addExaminerConfirm(){
            this.$refs.addExaminerFormRef.validate(validate => {
                if(validate){
                    // 编辑审批人表单提交
                    if(this.addExaminerForm.id){
                        this.$post('/base/updateApprover.do',this.addExaminerForm)
                        .then(res => {
						  this.$message.success('编辑成功')
                            this.addExaminerFormDialogVisible = false;
                            // 更新当前页数据列表
                            this.loadExaminerManagement()
                        })
                        return
                    }
                    // 添加审批人表单提交
                    const data = {
                        // name:this.addExaminerForm.name,
                        type:this.addExaminerForm.type,
                        managerid:this.addExaminerForm.managerid,
                    }
                    console.log(data)
                    this.$post('/base/insertApprover.do',data)
                    .then(res => {
					  this.$message.success('添加成功')
                        this.addExaminerFormDialogVisible = false;
                        this.loadExaminerManagement(1);
                        console.log(res)
                    })
                    .catch((err) => {
                        // that.$message.error(err.message.data);
                    });
                }

            })
        },
        // 删除
        deleteExaminer(id){
            const that = this;
            this.$confirm("是否移除审批人?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
            .then(() => {
                this.$post("/base/deleteApprover.do", { id })
                .then((res) => {
                that.$message({
                    type: "success",
                    message: "删除成功!",
                });
                that.loadExaminerManagement();
                })
                .catch((err) => {
                that.$message.error(err.message)
                });
            })
            .catch(() => {
                this.$message({
                type: "info",
                message: "已取消删除",
                });
            });
        },
    }
}
</script>

<style scoped>
.input-box {
  width: 180px;
  margin: 0 5px;
}

.flex-box {
  display: flex;
  justify-content: space-between;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.picker {
  margin-left: 10px;
}
</style>
